import { Box, Button, TextField, Typography, Alert, Paper, } from "@mui/material"
import { useState } from "react"
import { SingleSelector } from "../Component/MuiEx"
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { apiResult, getUserSession } from "../Utils/Common"
import apis from "../api"
import configure from "../app/config"
import { loadStripe } from "@stripe/stripe-js"


const CardSetupForm = (props) => {
    let { type, orderid, amount, token, time, onCancel } = props
    const [error, setError] = useState()
    const [submitting, setSubmitting] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    getUserSession(apis)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError()
        if (!stripe || !elements || submitting) {
            setError('Parameters error')
            return;
        }
        setSubmitting(true)
        let result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://member.axisfencingclub.com/memberapi/member/paycallback?orderid=" + orderid + "&token=" + token + "&time=" + time,
                // return_url: "http://localhost:8011/memberapi/member/paycallback?orderid=" + orderid + "&token=" + token + "&time=" + time
            }
        })
        setSubmitting(false)
        if (result.error) {                //console.log(result.error)
            setError(result.error.type + ":" + result.error.message)
            return
        }
        //let current_payment_method = result.setupIntent.payment_method
        // apis.payEvent({ payment_method:current_payment_method,amount:amount,}).then(ret => {

        // }).catch(error => {
        //     setError(error.message)
        // })
    }
    const handleCancel = () => {
        onCancel && onCancel()
    }
    return <Paper sx={{ mt: 2, p: 2, bgcolor: "#eee4" }}>
        <Typography>Your order No. <b>{type}-{Date.now()}-{orderid}</b></Typography>
        <Typography sx={{ mb: 2, mt: 1 }}>You need to pay <b>${amount}</b></Typography>
        <Typography sx={{mb:2,color:'#059c'}} variant='body1'>Payment services and credit card forms are provided by third-party professional payment service company (<a href="https://www.stripe.com" target="_blank" >Stripe</a>). We will not keep any credit card information.</Typography>        
        <PaymentElement />
        {error && <Alert severity='error' onClose={() => setError()}>{error}</Alert>}
        <Button variant='contained' disabled={submitting} sx={{ mt: 4, mb: 2, mr: 2 }} onClick={handleSubmit}>Recharge</Button>
        <Button variant='outlined' disabled={submitting} sx={{ mt: 4, mb: 2, mr: 2 }} onClick={handleCancel}>Cancel</Button>
    </Paper>
}

const Recharge = (props) => {
    const [error, setError] = useState()
    const [fields, setFields] = useState({ type: 0, amount: 0,member_id:0 })
    const [token, setToken] = useState()
    const [kids,setKids] = useState()
    const [kidids,setKidids] = useState()
    const [isRecharging, setIsRecharging] = useState(false)
    const [secret, setSecret] = useState()
    const [tokenTime, setTokenTime] = useState()
    const types = ["Private Class", "Membership for Beginner", "Membership for Intermediate", "Membership for Competitive", "Membership for Adult", "Membership for baby"]
    const amounts = [0, 3000, 4600, 5000, 3000, 2000]
    const [orderid, setOrderid] = useState(0)
    const stripePromise = loadStripe(configure.stripe_live);
    const handleChange = (name, value) => {
        setFields({ ...fields, [name]: value })
    }
    const handleTypeChange = (n, v) => {
        if (v != 0) {
            setFields({ ...fields, amount: amounts[v], type: v })
            if(!kids) {
                apis.loadKidsProfile().then(ret=>{
                    apiResult(ret,data=>{
                        let mms = []
                        let mmids = []
                        data.members.map((m)=>{
                            mms.push(m.name)
                            mmids.push(m.id)
                        })
                        setKids(mms)
                        setKidids(mmids)
                    },setError)
                })
            }
        } else {
            setFields({ ...fields, type: v })
        }
    }
    const handleNext = () => {
        setError()
        if (fields.amount < 50) {
            setError("The amount can't be less than $50.")
            return
        } else if (fields.type > 0 && amounts[fields.type] != fields.amount) {
            setError("The amount is invalid.")
            return
        }
        apis.setupRecharge(fields).then(ret => {
            apiResult(ret, data => {
                setOrderid(data.orderid)
                setSecret(data.secret)
                setToken(data.token)
                setTokenTime(data.time)
                setIsRecharging(true)
            }, setError)
        })
    }

    return <Box >
        <Paper sx={{ p: 2, bgcolor: "#eee4" }}>
            <Typography variant="h4" sx={{mb:2}}>Recharge</Typography>
            <Typography variant="subtitle2" sx={{mb:2,color:"#888"}}>You can choose the items that need to be recharged. Private Class is for private class recharge, and others are for recharge of different types of membership annual fees.</Typography>
            <SingleSelector labelBold={true} disabled={isRecharging} label={"Choose recharge item"} defaultValue={0} onChange={(n, v) => handleTypeChange(n, v)} name="type" items={types} values={[0, 1, 2, 3, 4, 5]}></SingleSelector>
            {fields.type != 0 && kids && <SingleSelector labelBold={true} items={kids} values={kidids} label={"Choose a kid"} onChange={(n,v)=>handleChange("member_id",v) } />}            
            <TextField sx={{mt:4}} InputLabelProps={{ shrink: true }} disabled={fields.type != 0 || isRecharging} value={fields.amount} margin="normal" fullWidth label={"Amount (CAD $)"} onChange={(e) => { handleChange("amount", e.target.value) }} />
            {error && <Alert severity="error">{error}</Alert>}
            {!secret && <Button variant="contained" sx={{ mt: 4, padding: '6 35', fontSize: '14px' }} disabled={fields.amount == 0 || (fields.type != 0 && fields.member_id == 0)} onClick={handleNext}>Next</Button>}
        </Paper>
        {secret && <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
            <CardSetupForm type={fields.type} orderid={orderid} amount={fields.amount} token={token} time={tokenTime} onCancel={() => { setIsRecharging(false); setSecret(); }} />
        </Elements>}
    </Box>
}
export default Recharge