import { useEffect, useState } from "react"
import apis from "../api"
import { apiResult, getUserSession } from "../Utils/Common"
import { useParams } from "react-router";
import { Box, Grid, Paper, Stack, Alert, Typography, Pagination, Button } from "@mui/material"
import { getBalanceProduct } from "../Utils/balance.config";
import BalanceView from "./fragement/BalanceView";
const Transactions = () => {
    const [transactions, setTransactions] = useState()
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [error, setError] = useState()
    getUserSession(apis)
    const params = useParams()
    const loadTransactions = (page, pagesize, countdata) => {
        apis.loadTransactions(page, pagesize, countdata, params.id ? params.id : 0).then(ret => {
            apiResult(ret, data => {
                countdata === 1 && setTotal(data.total)
                setTransactions(data.data)
            }, setError)
        })
    }
    useEffect(() => {
        loadTransactions(currentPage - 1, pageSize, 1)
    }, [])
    const handleChangePage = (event, page) => {
        setCurrentPage(page)
        loadTransactions(page - 1, pageSize, 0)
    }

    return (

        <Box>
            <Typography variant="h6" sx={{mb:2}}>Transaction History</Typography>
            {error && <Alert severity="error" onClose={() => { setError() }}>{error}</Alert>}
            {transactions && <Box>
                {transactions.map((t, i) => {
                    let color = '#ff5722af'
                    if (t.action === 'recharge') color = "#3c3"
                    else if (t.action === 'refund') color = "#288"

                    return <>
                        <Paper sx={{ p: { xs: 1, sm: 2 }, mb: 1, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Typography sx={{ flex: '1 1 100%', mb: 1, color: '#059c' }} component="div" variant="body2" >{('#' + t.id) + " "}<font style={{ color: '#cf1e3e' }}>{t.member && t.member.name ? t.member.name : ''}</font></Typography>
                                    <Typography component="div" variant="body2" sx={{ mt: '2px', mb: '2px' }}>{t.user_order ? t.user_order.product_name : getBalanceProduct(t.user_balance.type).label}</Typography>
                                    {t.user_order && t.user_order.count != 0 && <Typography component="div" variant="body2" >{t.user_order.count} minutes</Typography>}
                                    {t.note && <Typography component="div" variant="body2" sx={{ color: '#0005' }}>{t.note}</Typography>}
                                </Grid>
                                <Grid item xs={4} >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <Typography sx={{ color: '#0005', mb: 1 }} component="div" variant="body2" >{t.user_order ? t.user_order.order_date.substring(0, 10) : new Date(t.create_time).toLocaleDateString()}</Typography>
                                        <Typography component="div" variant="body1" sx={{ fontWeight: 'bold' }}>${t.amount}</Typography>
                                        <Typography component="div" variant="body2" sx={{ color: color }} >{t.action}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Paper>
                    </>
                })}
                {total/pageSize > 1 && <Pagination
                    component="div"
                    count={Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1)}
                    page={currentPage}
                    onChange={handleChangePage}
                    sx={{ mt: 2 }}
                //rowsPerPage={pageSize}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                />}
                {total == 0 && <Paper sx={{ p: { xs: 1, sm: 2 }, mb: 1, ml: { xs: 2, sm: 2 }, mr: { xs: 2, sm: 2 } }}>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/nodata.png" />
                    </Box>

                </Paper>}
            </Box>}
        </Box>
    )
}
export default Transactions