
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

export default function Terms() {
    return (<>
        <Container component="main" maxWidth="lg" sx={{ marginTop: 10 }}>
            <CssBaseline />
            <Box sx={{ marginTop: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <img src="/logo1.jpg" style={{ height: 150 }} />
                <Typography variant='h4' sx={{ fontWeight: 'bold',mt:2 }}>Axis Fencing Club Agreement (2022-2023)</Typography>
            </Box>
            <Box sx={{ display: 'flex',mt:6, flexDirection: 'column', alignItems: 'left', }}>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>ARTICLE I: MEMBERSHIP</Typography>
                <Typography variant='h6'>GRANT OF MEMBERSHIP AND PERIOD OF VALIDITY</Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                    <b>Grant of Membership:</b> Members should be in good health and possess the full capacity to become members. Those who are not in possession of the full capacity to become members must have their parent or lawful guardian sign for them. Persons who are 18 years or younger must have their parent or lawful guardians sign for them.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                    <b>Period of Validity:</b> On the day that fees are paid, members should take note of their membership’s period of validity. Membership takes effect from the date that member status is granted.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                    2022-2023 Full Yearly Membership: is valid for 10 months from Sep-01-2022 until June-30-2023. New members enrolled in the mid of the year, the yearly membership will be prorated. Monthly Membership: is valid for a month.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>TERMINATION</Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Termination of Membership:</b> If members discontinue their training; a written application (Membership termination form) must be completed and submitted to Axis Fencing Club. After these have been submitted, reviewed, and approved by Axis Fencing Club, a refund may be issued. Monthly payments and single payments are not refundable. If member failed to submit Membership termination form or membership extension form, the membership will automatically terminate once the membership validity ends.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Refunds: </b>All months subtracted (non-refundable months) from the total annual fee are counted as monthly fees without the annual discount. Any months that are not attended in full will be counted as a full month and will not be refundable, same terms as previously will be applied.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                Yearly Member fee - (Monthly member fee × used months) = Refund
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                All Refund will deduct 5% Administration fee.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                A membership termination form must be submitted stating the reason for termination along with a signature of the parent or lawful guardian for processing the membership termination. Members must state the reason for termination upon the form. Upon submission and processing of the form, a member may be issued a cheque made out in his or her name in the refund amount subject to final determination by Axis Fencing Club. Members and their parents or lawful guardians are asked to read this Agreement in detail, and to consider carefully that completion of renewal procedures is viewed as recognition of this clause. Termination due to a member’s misconduct or its agent is not subjected to refund.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>EXTENSION</Typography>                
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Extension of Membership:</b> No extensions will be granted if member is absent for less than 30 days consecutively. If member is absent for a minimum of 30 days consecutively, an extension of membership may be granted upon request. A membership extension form must be submitted stating the reason of absence, dates and length of absence, along with a signature of the parent or lawful guardian for processing the membership extension. A member is granted a maximum total of 60 days of extension even if the absence is longer than the stated amount (60 days). Extensions are no longer available after the total amount of extension is granted.  Members and their parents or lawful guardians are asked to read this Agreement in detail, and to consider carefully that completion of extension procedures is viewed as recognition of this clause. No extensions will be granted for Monthly Membership.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>Asking for Leave For Private Class</Typography>                
                <Typography variant='body1' sx={{ mt: 2 }}>
                If member has a private class leave, the member should notify the management office 24 hours in advance, otherwise half private lesson fee will be granted. Member who ask for private class leave need to obtain a written request for leave and obtain his/her coach’s signature. In order for a refund to be granted, this written request has to be submitted to the office, this written form is the only prove to grant a private class refund. The fee of the approval private class leave will be refunded next month. Members are allowed to transfer private lessons to other members. The private class fees must be handled by themselves, and the club management center will not refund the fees.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>ARTICLE II: RIGHTS, PRIVILEGES, AND RESPONSIBILITIES, SAFETY PROTOCOL</Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                All members please be advised by signing with the document, you will waive certain legal rights. Please read carefully of the following content. All members will be waive and forbear to exercise any and all rights and claims for damages, injuries or loses which members may presently or hereafter possess against the club including and no limit to the officers, instructors, and members.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                All members should be participating under their own free will and choice and during the training and practices including the warm-ups, members will involve individuals with different skills levels. All members will be willing to obey the rules and code conduct by the club. All the members should be aware that they are responsible for the own safety and health problems include the potential health risks, or equipment failure.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>
                MEMBER CODE OF CONDUCT, RIGHTS AND PRIVILEGES,
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Rights and Privileges:</b> All members enjoy the exclusive use of Axis Fencing Club facilities provided membership has been paid in full, no outstanding fees are owed, and all conditions of this Agreement have been complied with.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                <b>Disruptive Behavior:</b> If a member engages in any behavior during class time that seriously disrupts order in the facilities or that seriously interferes with the other members’ study, personal safety or physical and mental health, Axis Fencing Club reserves the right to expel that member. Membership fees will be refunded according to refund policies, but Axis Fencing Club is not responsible for any additional financial compensation.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                <b>Illegal Conduct:</b> Members, their parents and/or lawful guardians and other related persons must not engage in any behavior that is illegal, against regulations or in violation of public morality and order while at the Axis Fencing Club, and must not engage in defamation, slander or debasement of the Axis Fencing Club brand or engage in any related illegal activities. Otherwise, Axis Fencing Club has the right to immediately terminate the membership without refunding any membership fees that have already been paid and reserves the right to claim damages against the person who is liable.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                <b>Commitments of Members / Guardians:</b>
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                1) I, the member, do not have any of these illnesses or health issues: hypertension, arteriosclerosis, heart disease, spine or neck disorders, epilepsy, thrombosis, asthma, herniated disc, previous major knee fractures or injuries or any infectious diseases, nor do not have any other illnesses which could impact normal physical exercise. I, the member do not have any history of illness which could impact normal physical exercise.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                2) I will carefully read and understand all the regulations in the Axis Fencing Club Handbook which will be provided to me at the commencement of membership. I agree and am willing to abide by the Axis Fencing Club Handbook.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                3) I assume full liability for any damages that I may suffer as a result of not abiding by relevant content in this Agreement and in the Axis Fencing Club Handbook, and Axis Fencing Club need not assume any liability. I assume full liability for compensation for damages or injury sustained by others as a result of my failure to abide by safety regulations and reminders in this Agreement and in the Axis Fencing Club Handbook. If Axis Fencing Club initiates fulfillment of payment obligations accordingly, I will compensate Axis Fencing Club for all costs incurred (including but not limited to compensation, litigation/arbitration fees, legal fees, transportation fees and appraisal fees, etc.)
                </Typography>                
                <Typography variant='body1' sx={{ mt: 1 }}>
                <b>Responsibility of Fencers:</b>
                </Typography>                
                <Typography variant='body1' sx={{ mt: 1 }}>
                1. Fencers arm, equip and clothe themselves and fence <b>at their own responsibility and at their own risk</b>.
                </Typography>                
                <Typography variant='body1' sx={{ mt: 1 }}>
                2. It is obligatory for any fencer who warms up or trains with another fencer on site at an official FIE competition (including in the training halls linked to the competition) to wear <b>fencing clothing and equipment</b> which conforms with the FIE regulations. Any person giving a lesson must wear at least a fencing master’s plastron as well as a fencing glove and a mask conforming with the regulations.
                </Typography>                
                <Typography variant='body1' sx={{ mt: 1 }}>
                Any fencer taking a lesson must wear at least a mask and a glove.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                3. The <b>safety measures</b> specified in the Rules and in the standards contained in the annexes to them and the methods of control laid down in the present Rules (cf. Material Rules) are only designed to reinforce the fencers’ safety and cannot guarantee it. They cannot, therefore, whatever the manner in which they are applied, impart responsibility to the FIE, or to the organizers of competitions, to the officials or personnel who carry out such organization, or to those who may cause an accident...Based on the FIE Rules, at Axis Fencing Club:\
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                It’s against the law when you fence without the equipment (mask, plastron, glove, clothing) with other students. It is obligatory for any student who training or warming up or playing game with coach or another fencer on site at our fencing field to wear fencing clothing and equipment which conforms with FIE regulations. A student must know it is his (or her) own responsibility for safety during the whole training program. We are only designed to warm and reinforce the safety of students and cannot guarantee. Students are obligated to know their own responsibility and their own risk. They cannot apply any manners of, or impart responsibility on the club or coach or club staff or those who may cause an accident.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ARTICLE III: RELEASE OF INFORMATION
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>COMMUNICATION</Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Communication:</b> In order to ensure members’ rights and interests are realized in a timely and efficient way, members or their guardians must provide Axis Fencing Club with an effective method of contact, including but not limited to mobile telephone, home telephone, contact address and e-mail address. Members and/or their parents or lawful guardians should notify Axis Fencing Club immediately of any chance to the contact information listed on the membership form. Members and/or their parent or lawful guardians shall bear responsibility for any losses that result from their failure to notify Axis Fencing Club of any change on contact information.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Photos and Videos:</b> Photos and videos may be taken of members while they are studying or training at Axis Fencing Club. Members and/or their parents or lawful guardians’ consent to the free, long-term and legal use by Axis Fencing Club or a third party authorized by Axis Fencing Club of the photo or video materials of the members themselves and/or their parents or lawful guardians, including for advertising, decoration, business promotion, or other commercial or charitable purposes pertaining to Axis Fencing Club or a their party authorized by Axis Fencing Club. All copyright and ownership of photos and videos taken while on the property of Axis Fencing Club or while participating in any Axis Fencing Club activities and/or events belong to Axis Fencing Club.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2, fontWeight: 'bold' }}>PRIVACY POLICY</Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Privacy:</b> Information is gathered from this Agreement in order to collect billing information, member information, insurance information and contact information. By supplying their email address, members permit Axis Fencing Club to communicate electronically with them. No member may utilize and distribute contact email addresses of other member’s email address for their own personal use unless given consent by the party(ies). Membership information management:
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                Members or their guardians agree to the club's management and ownership of all information related to personal data and physical index data. Members have the right to access their own data. Anyone who needs to access the membership information of the 16-year-old or above member, should obtain their own consent should their guardian access the information.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ARTICLE IV: LIABILITY ,LOST, DAMAGED OR STOLEN PROPERTY
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Lost, Damaged or Stolen Property:</b> Axis Fencing Club is hereby released from liability should members’ property or belongings become lost, damaged or stolen within the property of Axis Fencing Club’s arena or during participation in any of Axis Fencing Club’s activities. Members bear the sole responsibility for any losses incurred as a result of losing or damaging their property or belongings.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ACTS OF GOD
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Acts of God:</b> An Act of God refers to objective situations that are unforeseeable, unavoidable and impossible to overcome, including but not limited to natural disasters (earthquakes, flood, fire, hurricanes, etc.), government use of related venues, epidemic, strikes, social instability, coup d'etat, riots, states of emergency, war, etc. If Axis Fencing Club is unable to fulfil its contractual obligations as a result of an Act of God, then Axis Fencing Club is absolved of any liability based on the impact of Act of God events. No class extension may be granted due to the impact of Acts of God events.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ARTICLE V: MISCELLANEA
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Amendments:</b> Axis Fencing Club reserves the right to make the relevant adjustments to class content, class times, and class arrangements according to class scheduling needs, and shall notify members in advance. Axis Fencing Club is not liable for any compensation to members resulting from this.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Right of Final Interpretation:</b> Axis Fencing Club has full ownership of the rights of final interpretation and revision of the terms of this Agreement. Rules and a corresponding regulatory framework for the training center and member management formulated and issued by Axis Fencing Club from time to time constitute an integral part of the provisions of this Agreement, and the member agrees to be bound by them.
                </Typography>                
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Breaks:</b> Axis Fencing Club reserves the rights to close the facility and stop all classes for a maximum of 60 days per year for breaks and recovery purposes and shall notify members in advance. Axis Fencing Club is not liable for any compensation to members resulting from this.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Effect of Agreement:</b> This Agreement shall take effect upon signing
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ARTICLE VII: CONSENT TO MEDICAL TREATMENT
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                Consent for Medical Treatment: This is to certify that I give my consent to the Axis Fencing Club and its representatives to obtain medical care from any licensed physician, hospital or clinic for the above named member for any injury or illness that may arise during activities associated with fencing classes at Axis Fencing Club.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                ARTICLE VIII: WAIVER OF LIABILITY
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Assumption of Risks:</b>  Fencing is a physical activity which, by its very nature, carries with it certain inherent risk that cannot be eliminated regardless of the care taken to avoid injuries. Axis Fencing Club classes include activities that can involve strenuous exertions of strength using various muscle groups, quick movements involving speed and change of direction, and sustained physical activity which can place stress on the cardiovascular system.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                The specific risks vary from one activity to another, but the risks range from minor injuries such as scratches, bruises, and sprains to major injuries such as eye injury, joint or back injuries, heart attacks and concussions.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                <b>Waiver of Liability:</b> In consideration of the member or the child’s participation in the Axis Fencing Club fencing activities, the member or the legal guardian of the child member acknowledges, agrees to and understands that he/she is fully aware of and appreciates the risks, including the risk of serious injury or death, associated with the member or the child’s participation in fencing activities within Axis Fencing Club. The member or the legal guardian of the child further agrees on behalf of the member, his/her heirs, and personal representatives, that Axis Fencing Club, along with its coaches, volunteers, agents, and lessee of the premises, shall not be liable for any injury, loss of life or other loss or damage occurring as a result of the member’s participation in Axis Fencing Club events and/or activities, or as a result of equipment that may have been provided to the member for those activities.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                The member or the legal guardian UNDERSTANDS THAT THIS IS A LEGAL AGREEMENT. It is binding upon the member as well as upon his/her heirs, next of kin, executors, administrators, assigns and representatives, in the event of member’s death or incapacity.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                The member or the legal guardian HAS READ THIS WAIVER AND UNDERSTOOD ALL THE TERMS OF THIS AGREEMENT.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                THE MEMBER OR THE LEGAL GUARDIAN UNDERSTANDS THAT SUBSTANTIAL RIGHTS ARE GIVEN UP BY SIGNING IT.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                THE MEMBER OR THE LEGAL GUARDIAN IS SIGNING IT VOLUNTARILY ON AND AS OF THE DATE SET OUT BELOW.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                I HAVE READ AND UNDERSTAND THIS WAIVER AND RELEASE AND I AM AWARE THAT BY SIGNING THIS DOCUMENT I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HIERS, NEXT OF KIN, EXECUTORS, REPRESENTATIVES, SUCCESSORS, ASSIGNS, AND ADMINISTRATORS MAY HAVE AGAINST THE RELEASES. I AM OVER THE AGE OF 18 YEARS. IF THE PARTICIPANT IS UNDER THE AGE OF 18, THIS RELEASE AND CONSENT FORM MUST ALSO BE SIGNED BY A PARENT OR GUARDIAN.
                </Typography>
                <Typography variant='h5' sx={{ mt: 2, fontWeight: 'bold' }}>
                AXF Rules
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                1. Be respectful while fencing. Lose with honor and win with dignity.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>                
                2. Do not appear on the strip with defective equipment.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                3. Respect and protect the Club; do not damage walls, floors or strips. Do not hit the wall, floor or strip with your blade and tip.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                4. Do not throw any equipment under any circumstance. Respect Club equipment as your own property.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                5. Before leaving the Club put all your equipment in your fencing bag. Any fencing gear left thrown on the floor will be discarded.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                6. Do not throw your trash on the floor around the club area; all food and drinks are NOT allowed in the training area.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                7. Parents MUST sit in the waiting room or wait outside. Parents will not be permitted to sit in the fencing or training area.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                8. If you are late to class, join the class with the least amount of disruption. If you can’t make a scheduled lesson, it is your responsibility to let the coach / office knows. Fencers will be restricted from group / private lessons if you are more than 15 minutes late.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                9. Less experienced fencers should ask more experienced fencers to bout. Experienced fencers should encourage and teach beginning fencers.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                10. Safety is most important; always check your equipment for defects in gloves, masks and blades. Never aim your weapon at a person that is unprotected.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                11. Never argue with a referee, opponent, coach or instructor.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                12. Active members of AXF agree to take lessons exclusively from AXF coaches-recognizing the need for consistency of training, effective monitoring of progress, and developing a strong student – coach relationship.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                13. Active AXF members of AXF that wish to participate in camps outside of AXF will need to talk to Head Coach for approval.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                14. There will be ABSOLUTELY NO TOLERATION for bullying. First offense will be verbal to child and parents; 2nd you will be suspended from the club until all parties involved find a resolution.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                15. MUST warm up before attending the private lesson.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                16. Disrespect from parents or fencers to coaches or staff will not be tolerated. Violators will be removed from premises immediately. Fencers / Parents who don’t comply with this code of conduct may be subject to exclusions from fencing activities, lessons or expulsion from the club. Repeat violations will be reviewed by staff.
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                17. No electronic devices (cell phone or Ipad) allow to be bring into the training area during the group lesson and private lesson. This is applied to both coach and members.
                </Typography>
                <Typography variant='body1' sx={{ mt: 2 }}>
                Rules above are set forth by AXF AD Department,AXF reserves the right of final explanation.
                </Typography> 
            </Box>
        </Container>
    </>
    );
}
