import { Box, Paper, Typography, Avatar } from "@mui/material"
import { ErrorOutline, TaskAlt } from "@mui/icons-material";
const PayCallback = () => {

    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get("error");
    const status = queryParams.get("status");
    const msg = queryParams.get("msg")

    return (
        <Paper sx={{ p: { xs: 1, sm: 2 }, mb: 1, ml: { xs: 2, sm: 2 }, mr: { xs: 2, sm: 2 } }}>
            {!error && <Box sx={{ width: '100%', mt: 6, mb: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar alt="Success" sx={{ bgcolor: "#fff", mt: 2, mb: 2 }}><TaskAlt sx={{ color: "green" }} fontSize="large"></TaskAlt></Avatar>
                <Typography variant="h6" sx={{ mb: 1, color: "green" }}>Recharge successful!</Typography>
                <Typography variant="subtitle2" sx={{ color: "#888" }}>Congrats on the successful recharge:)</Typography>
            </Box>}
            {error && <Box sx={{ width: '100%', mt: 6, mb: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar alt="Failed" sx={{ bgcolor: "#fff", mt: 2, mb: 2 }}><ErrorOutline sx={{ color: "red" }} fontSize="large" /></Avatar>
                <Typography variant="h6" sx={{ mb: 1}}>Recharge failed, error code <font color="red">{error}</font></Typography>
                {error == "E001" && <Typography variant="subtitle2" sx={{ color: "#888" }}>Token verification failed after recharge</Typography>}
                {error == "E002" && <Typography variant="subtitle2" sx={{ color: "#888" }}>Recharge failed, please try again later</Typography>}
                {error == "E003" && <Typography variant="subtitle2" sx={{ color: "#888" }}>The recharge order status is abnormal, the original status is {status}</Typography>}
                {error == "E009" && <Typography variant="subtitle2" sx={{ color: "#888" }}>The recharge order does not exist</Typography>}
                {error == "F004" && <Typography variant="subtitle2" sx={{ color: "#888" }}>{msg}</Typography>}
            </Box>}

        </Paper>
    )
}
export default PayCallback