import React, { useEffect } from 'react'
import { Slide,  Stack, Box } from "@mui/material"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DailySchedule = (props) => {
    const { dateSchedule } = props
    //const end = dateSchedule.wod >= 1 && dateSchedule.wod <= 5 ? 1275 : 1215
    const unitHeight = 15
    const unitWidth = Math.ceil(100 / (Object.keys(dateSchedule.schedule).length + 1)) + '%'
    const times = []    
    let bb = 840 //dateSchedule.wod >= 1 && dateSchedule.wod <= 5 ? 840 : 480
    let ee = dateSchedule.wod >= 1 && dateSchedule.wod <= 5 ? 1275 : 1215
    Object.keys(dateSchedule.schedule).map((item) => {
        let coach = dateSchedule.schedule[item]
        coach.lessons.map(lesson=>{
            if (lesson.begintime < bb) {
                bb = lesson.begintime
            }
            if(lesson.begintime + lesson.duration > ee) {
                ee = lesson.begintime + lesson.duration
            }
        })        
    })
    const begin = bb
    const end = ee
    for (let i = begin; i <= end; i += 15) {
        times.push(i)
    }
    const getTimeString = (time) => {

        let h = Math.floor(time / 60)
        let m = time % 60
        let h1 = Math.floor((time + 15) / 60)
        let m1 = (time + 15) % 60
        const ff = (n) => {
            return n < 10 ? ("0" + n) : n
        }
        return ff(h) + ":" + ff(m) + (Object.keys(dateSchedule.schedule).length <= 3 ? ("~" + ff(h1) + ":" + ff(m1)) : '')
    }

    return <Stack direction={"row"} sx={{ width: '100%' }}>
        <Stack direction="column" sx={{ width: unitWidth, }}>
            <Box sx={{ boxSizing: 'border-box', height: unitHeight + "px", border: "1px solid #0003", }}><span style={{ fontSize: "10px" }}></span></Box>
            {times.map((time) => {
                return <Box key={time} sx={{ boxSizing: 'border-box', height: unitHeight + "px", lineHeight: '11px', borderLeft: "1px solid #0003", borderBottom: "1px solid #0003", borderRight: "1px solid #0003", pl: 1 }}><span style={{ fontSize: "9px" }}>{getTimeString(time)}</span></Box>
            })}
        </Stack>
        {Object.keys(dateSchedule.schedule).map((item, index) => {
            let coach = dateSchedule.schedule[item]
            return <Stack direction="column" sx={{ width: unitWidth, }}>
                <Box sx={{ boxSizing: 'border-box', height: unitHeight + "px", borderBottom: "1px solid #0003", borderRight: "1px solid #0003", borderTop: "1px solid #0003", paddingLeft: 1, lineHeight: '11px' }}><span style={{ fontSize: "10px", fontWeight: 'bold' }}>{coach.coach.name}</span></Box>
                <Box sx={{ boxSizing: 'border-box', height: (unitHeight * times.length) + "px", borderBottom: "1px solid #0003", width: '100%', borderRight: "1px solid #0003", position: "relative", paddingLeft: 1 }}>
                    {coach.lessons.map((lesson) => {
                        return lesson.begintime >= begin && <Box key={lesson.id} sx={{ boxSizing: 'border-box', height: (unitHeight * (lesson.duration / 15)) + "px", lineHeight: '16px', width: '100%', bgcolor: '#059c', color: '#fff', fontSize: '9px', lineHeight: '10px', position: 'absolute', left: 0, top: (lesson.begintime - begin) / 15 * unitHeight + "px" }}></Box>
                    })}
                </Box>
            </Stack>
        })}
    </Stack>

}
export default DailySchedule