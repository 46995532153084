import { useEffect, useState, useRef } from "react"
import apis from "../../api"
import { apiResult, getUserSession } from "../../Utils/Common"
import { Box, Grid, Card, Button, CardHeader, Avatar, CardContent, Stack, Alert, Typography, } from "@mui/material"
import DailySchedule from "./DailySchedule"

const CoachesSchedule = () => {
    const schedules = useRef()
    const [dates, setDates] = useState()
    const [error, setError] = useState()
    const [week, setWeek] = useState(0)
    const weeks = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
    getUserSession(apis)

    const getOneWeekDates = (dd) => {
        let now = dd ? new Date(dd) : new Date()
        let dates = []
        for (let i = 0; i < 7; i++) {
            let y = now.getFullYear()
            let m = now.getMonth() + 1
            let d = now.getDate()
            let w = now.getDay()
            let date = y + "-" + (m < 10 ? ("0" + m) : m) + "-" + (d < 10 ? ("0" + d) : d)
            dates.push({ date: date, wod: w, schedule: {} })
            now.setDate(now.getDate() + 1)
        }
        return dates
    }


    const loadSchedules = (from) => {
        apis.loadSchedules(from ? from : 0).then(ret => {
            apiResult(ret, data => {
                schedules.current = data
                let date = new Date()
                setWeekSchedule(data, date.setDate(date.getDate() + from))
                //setSchedules(data)
            }, setError)
        })
    }
    const setWeekSchedule = (schedules, dd) => {
        let dds = getOneWeekDates(dd)
        schedules.map(d => {
            for (let i = 0; i < dds.length; i++) {
                if (dds[i].wod == d.wod && d.sdate == dds[i].date) {
                    //dds[i].schedule.push(d)
                    if (dds[i].schedule['coach_' + d.coach.id]) {
                        dds[i].schedule['coach_' + d.coach.id].lessons.push(d)
                    } else {
                        dds[i].schedule['coach_' + d.coach.id] = { coach: d.coach, lessons: [d] }
                    }
                }
            }
            return true
        })
        setDates(dds)
    }
    useEffect(() => {
        loadSchedules()
    }, [])

    const handleWeek = (w) => {
        setWeek(w)
        loadSchedules(w * 7)
    }


    return <Box>
        {error && <Alert severity="error" onClose={() => { setError() }}>{error}</Alert>}

        <Grid container spacing={2}>
            {dates && dates.map((item, index) => {
                let curDay = item.date == new Date().toISOString().substring(0, 10)
                return <Grid item key={index} xs={12} sm={4} md={4} >
                    <Card key={index}>
                        <CardHeader sx={{ bgcolor: curDay ? '#cf1e3e' : '#eee', color: curDay ? "#fff" : '#000' }} title={item.date}
                            avatar={<Avatar sx={{ bgcolor: '#fff', color: '#cf1e3e' }} size={"small"} fontSize={"small"} aria-label="recipe">{weeks[item.wod]}</Avatar>} />
                        <CardContent sx={{ minHeight: 150, padding: '0px !important' }}>
                            <DailySchedule dateSchedule={item} />
                        </CardContent>
                    </Card></Grid>
            })}
        </Grid>
        <Stack direction={"row"} sx={{ mt: 2 }}>
            <Typography component="h6" variant="h6" sx={{ fontWeight: 'bold', flex: '1 1 60%' }}> </Typography>
            {week > 0 && <Button variant="outlined" sx={{ color: '#cf1e3e', borderColor: '#cf1e3e' }} onClick={() => handleWeek((week - 1) >= 0 ? (week - 1) : 0)} >{"Prev"}</Button>}
            <Button variant="outlined" onClick={() => handleWeek(week + 1)} sx={{ ml: 2, color: '#cf1e3e', borderColor: '#cf1e3e' }}>{"Next"}</Button>
        </Stack>
    </Box>
}

export default CoachesSchedule