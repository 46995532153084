import { useEffect, useState, useRef } from "react"
import apis from "../api"
import { apiResult, getUserSession } from "../Utils/Common"
import { Box, Grid, Link, Card, Button, CardHeader, Avatar, CardContent, Paper, Stack, Chip, Alert, Typography, Toolbar, Tabs, Tab } from "@mui/material"
import CoachesSchedule from "./fragement/CoachSchedule"
import MySchedule from "./fragement/MySchedule"

const MemberSchedule = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event, idx) => {
        setTabIndex(idx)
    }

    return <Box>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label={"My schedules"} key={0} />
            <Tab label={"Coach's Schedules"} key={1} />
        </Tabs>

        {tabIndex == 0 &&  <MySchedule />}
        {tabIndex == 1 && <CoachesSchedule />}

    </Box>
}

export default MemberSchedule