import { Box, Card, CardMedia, Grid, Typography, CardContent, CardActions, Button, Alert, Stack, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import apis from "../api"
import { apiResult, getUserSession } from "../Utils/Common"

const Competitions = () => {
    const [events, setEvents] = useState()
    const [error, setError] = useState()
    const navigate = useNavigate()
    getUserSession(apis)
    useEffect(() => {
        apis.getRaces().then(ret => {
            apiResult(ret, data => {
                setEvents(data)
            }, setError)
        })
    }, [])
    return <Box sx={{ p: 2, pt: { md: 4, xs: 2 }, height: "fit-content" }}>
        <Typography component="h6" variant="h6" sx={{ fontWeight: '', mb: 2,}}>Recent Competitions</Typography>
        {error && <Alert severity="error">{error}</Alert>}

        {events && events.map((event, index) => {
            return <Grid container spacing={2} key={index} sx={{ minHeight: "200px",mt:{xs:2,sm:0} }}>
                <Grid item xs={12} sm={0} sx={{ display:{sm:"none",xs:"block"} }}>
                    <Typography variant="h6">{event.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} sx={{overflow:"hidden"}}>
                    <img style={{ width: "100%",height:"100%", maxHeight: "150px" }} src={event.picture ? event.picture : "/fencing.jpg"}></img>
                </Grid>
                <Grid item xs={12} sm={9} sx={{ cursor: "pointer" }}>
                    <Stack direction={"column"}><Typography variant="h6" sx={{display:{xs:"none",sm:"block"}}}><a href={event.url} target="_blank">{event.name}</a></Typography>
                    <Typography component={"p"} variant="body2">{event.description}</Typography>
                    <Typography variant="subtitle2">Location: {event.location}</Typography>
                    <Typography variant="subtitle2" >Date: {event.sdate}</Typography>                    
                    </Stack>
                </Grid>
            </Grid>
        })}
        {(!events || events.length == 0) && <Paper sx={{ p: { xs: 1, sm: 2 }, mb: 1, ml: { xs: 2, sm: 2 }, mr: { xs: 2, sm: 2 } }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="/nodata.png" />
            </Box>

        </Paper>}
    </Box>
}

export default Competitions